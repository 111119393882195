import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react";
import Layout from "../components/layout"
import GeneralFooter from '../components/general_footer';
import { TitleHeader, Banner } from "../components/defaults/styles/elements"
import { color } from "../components/defaults/styles/theme"
import { Loader } from "../components/defaults/assets/svg/mobilenav"
import { CardGroup } from '../components/main/card_group';
import { isLoggedIn } from "../services/auth"


const seo = {
  frontmatter: {
    title: "Startups",
  },
}

const Startups = ({data}) => {

  let groupTitle = []
  let groups = []

  data.allAirtable.edges.forEach(({node}) => {
    let group = node.data.vertical ? node.data.vertical[0] : ''
    if (group && !(groupTitle.includes(group))) {
      groupTitle.push(group)
    } else if (!group && !(groupTitle.includes("undefined"))) {
      groupTitle.push("undefined")
    }
  })

  groupTitle.forEach((e, index) => {
    let elem = {}
    elem["fieldValue"] = e
    elem["edges"] = []
    data.allAirtable.edges.forEach(({node}) => {
      if ( (node.data.vertical && node.data.vertical[0] === e) || (!node.data.vertical && e === "undefined") ) {
        elem["edges"].push({"node": node})
      }
    })
    groups.push(elem)
  })

  const verticals = ["Enterprise 2.0", "Production", "Mobility"]; // ascending order

  verticals.forEach((e, index) => {
    let i = (groups.findIndex( x => x.fieldValue === e))
    groups.splice(0, 0, groups.splice(i, 1)[0])
  })

  const undefinedIndex = groups.findIndex( x => x.fieldValue === "undefined")
  if ( undefinedIndex > -1) {
    groups.splice(groups.length, 0, groups.splice(undefinedIndex, 1)[0])
  }

  return (
    <Layout seo={seo}>
      <TitleHeader
        title="Startups of Program 9"
        subtitle="<p>Each program, our Ventures Team scouts out the most disruptive (in a good way) and forward thinking startups out there. This program was certainly no exception and representing 11+ nations, we are proud to have each of the teams below as collaborators on our platform.</p><p>Spanning the verticals of mobility, production and enterprise, the startups range from: 
        Intelligent thermal sensing technologies, to social travel apps and gesture recognition software, range prediction for EVs to online education portals, and more! Together with the business units of our corporate partners they have successfully completed a PoC, pilot project or implementation – driving innovation forward! Enjoy checking out their profiles below.
        </p>"
        icon={<Loader />}
      />
      {!isLoggedIn() ? (
        <Banner
          type="lock"
          content="<p><b>EXPO 9: the Insider Edition</b><br />Additional startup information is exclusively published for partners of STARTUP AUTOBAHN powered by Plug and Play.</p><p>In order to request an invite code follow the process explained on the <a href='/register'>registration page</a>.</p>"
          css={css`
            z-index: 1;
            position: relative;
            a {
              white-space: nowrap;
            }
          `}
        />
      ) : (
        <Banner
          type="info"
          content="<p>Although the information below has been curated with the utmost attention to every detail, the STARTUP AUTOBAHN team cannot guarantee for its flawlessness.</p><p>If you would like to report mistakes or request changes please reach out to <a href=mailto:marketing-ger@pnptc.com>marketing-ger@pnptc.com</a></p>"
          css={css`
            z-index: 1;
            position: relative;
            a {
              white-space: nowrap;
            }
          `}
        />
      )}

      <section
        css={css`
          background: ${color.main_light};
          padding-top: 7rem;
          margin-top: -2.5rem;
        `}
      >
        {groups.map((edges, index) => (
          <CardGroup
            data={edges}
            key={index}
            table={data.allAirtable.distinct}
          />
        ))}
      </section>
      <GeneralFooter theme={color.main_light} />
    </Layout>
  )
}

export const query = graphql`
  query startups {
    allAirtable(filter: {data: {include: {eq: true}}, table: {eq: "startups"}}, sort: { fields: data___sort_startup, order: ASC }) {
      edges {
        node {
          id
          data {
            url
            vertical
            country
            city
            display_company
            startup
            startup_program
          }
          table
          recordId
        }
      }
    }
  }
`


export default Startups;